import ReactDOM from 'react-dom'
import React from 'react'

import App from './App'

function renderApp() {
	ReactDOM.render(<App />, document.getElementById('root'))
}

renderApp()
