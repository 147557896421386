import React, { FunctionComponent } from 'react'

import MrWafflePng from '../img/mr-waffle.png'

const MrWaffle: FunctionComponent = () => (
	<div className="mr-waffle">
		<img src={MrWafflePng} alt="Mr Waffle" />
	</div>
)

export default MrWaffle
