import React, { FunctionComponent } from 'react'
import Row from './Row'

type StatsProps = {
	//
}

const Stats: FunctionComponent<StatsProps> = props => {
	return (
		<div className="section stats">
			<div className="stats__inner">
				<h3>September 2024</h3>
				<div>
					<h4>Average daily players</h4>
					<span className="number">469,784</span>
				</div>
				<div>
					<h4>Monthly Waffles solved</h4>
					<span className="number">14,093,521</span>
				</div>
				<div>
					<h4>Total Waffles solved</h4>
					<span className="number">404,831,956</span>
				</div>
			</div>
		</div>
	)
}

export default Stats
