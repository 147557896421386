import React, { FunctionComponent } from 'react'

type SectionProps = {
	//
}

const Section: FunctionComponent<SectionProps> = props => {
	return <div className="section">{props.children}</div>
}

export default Section
