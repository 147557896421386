import React, { FunctionComponent } from 'react'

const Waffle: FunctionComponent = () => (
	<div className="waffle">
		<a href="https://wafflegame.net" target="_blank" rel="noreferrer" aria-label="Play Waffle">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 278 278">
				<path
					fill="#6FB05C"
					d="M.682 8.202a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52H8.202a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
				/>
				<mask id="a" width="53" height="53" x="0" y="0" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M.682 8.202a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52H8.202a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
					/>
				</mask>
				<g mask="url(#a)">
					<path
						fill="#649E53"
						d="M52.385 44.865c0 6.23-5.05 11.28-11.28 11.28H11.961c-6.23 0-11.28-5.05-11.28-11.28 0 2.076 3.367 3.76 7.52 3.76h36.662c4.154 0 7.521-1.684 7.521-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M18.95 35.675c-.42 0-.763-.097-1.026-.29a1.184 1.184 0 0 1-.447-.763c-.053-.334.008-.702.184-1.106l6.949-15.398c.228-.509.5-.868.816-1.08.333-.227.71-.341 1.132-.341.403 0 .763.114 1.079.342.333.21.614.57.842 1.08l6.976 15.397c.193.404.263.772.21 1.106a1.201 1.201 0 0 1-.447.79c-.246.175-.57.263-.974.263-.492 0-.878-.114-1.158-.342-.264-.246-.5-.615-.711-1.106l-1.711-3.975 1.421.922H20.977l1.422-.922-1.685 3.975c-.228.509-.465.877-.71 1.106-.246.228-.597.342-1.053.342Zm7.555-15.109-3.685 8.765-.684-.842h8.791l-.658.842-3.711-8.765h-.053Z"
				/>
				<path
					fill="#6FB05C"
					d="M226.296 8.202a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
				/>
				<mask id="b" width="52" height="53" x="226" y="0" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M226.296 8.202a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
					/>
				</mask>
				<g mask="url(#b)">
					<path
						fill="#649E53"
						d="M277.999 44.865c0 6.23-5.051 11.28-11.28 11.28h-29.142c-6.23 0-11.281-5.05-11.281-11.28 0 2.076 3.367 3.76 7.52 3.76h36.663c4.153 0 7.52-1.684 7.52-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M244.329 35.675c-.492 0-.869-.14-1.132-.421-.263-.281-.395-.667-.395-1.159V18.276c0-.509.14-.895.421-1.158.281-.28.667-.421 1.159-.421.438 0 .78.087 1.026.263.263.158.5.439.711.842l6.501 12.03h-.842l6.501-12.03c.211-.403.438-.684.685-.842.245-.176.587-.263 1.026-.263.491 0 .869.14 1.132.42.263.264.395.65.395 1.159v15.82c0 .49-.132.877-.395 1.158-.246.28-.623.42-1.132.42-.491 0-.868-.14-1.132-.42-.263-.281-.394-.667-.394-1.159V21.908h.579l-5.528 10.082c-.176.298-.36.517-.553.658-.193.14-.456.21-.789.21-.334 0-.606-.07-.816-.21a2.076 2.076 0 0 1-.553-.658l-5.58-10.108h.632v12.213c0 .492-.132.878-.395 1.159-.247.28-.624.42-1.132.42Z"
				/>
				<path
					fill="#6FB05C"
					d="M226.296 233.817a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
				/>
				<mask id="c" width="52" height="52" x="226" y="226" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M226.296 233.817a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
					/>
				</mask>
				<g mask="url(#c)">
					<path
						fill="#649E53"
						d="M277.999 270.479c0 6.23-5.051 11.281-11.28 11.281h-29.142c-6.23 0-11.281-5.051-11.281-11.281 0 2.077 3.367 3.761 7.52 3.761h36.663c4.153 0 7.52-1.684 7.52-3.761Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M245.211 261.289c-.544 0-.965-.149-1.263-.447-.282-.316-.422-.745-.422-1.29v-15.503c0-.561.14-.992.422-1.29.298-.298.719-.447 1.263-.447.544 0 .956.149 1.237.447.298.298.448.729.448 1.29v6.212h9.554v-6.212c0-.561.14-.992.421-1.29.298-.298.72-.447 1.264-.447s.956.149 1.237.447c.298.298.448.729.448 1.29v15.503c0 .545-.15.974-.448 1.29-.281.298-.693.447-1.237.447-.544 0-.966-.149-1.264-.447-.281-.316-.421-.745-.421-1.29v-6.475h-9.554v6.475c0 .545-.141.974-.422 1.29-.281.298-.702.447-1.263.447Z"
				/>
				<path
					fill="#6FB05C"
					d="M.682 233.817a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521H8.202a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
				/>
				<mask id="d" width="53" height="52" x="0" y="226" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M.682 233.817a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521H8.202a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
					/>
				</mask>
				<g mask="url(#d)">
					<path
						fill="#649E53"
						d="M52.385 270.479c0 6.23-5.05 11.281-11.28 11.281H11.961c-6.23 0-11.28-5.051-11.28-11.281 0 2.077 3.367 3.761 7.52 3.761h36.662c4.154 0 7.521-1.684 7.521-3.761Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M20.295 261.289c-.51 0-.904-.14-1.185-.421-.263-.281-.395-.684-.395-1.21v-15.662c0-.544.132-.956.395-1.237.281-.298.64-.447 1.08-.447.403 0 .701.078.894.236.21.14.457.387.737.737l10.081 13.082h-.684v-12.45c0-.508.132-.903.395-1.184.28-.281.676-.421 1.184-.421.51 0 .895.14 1.159.421.263.281.394.676.394 1.184v15.793c0 .492-.122.877-.368 1.158-.246.281-.58.421-1 .421-.404 0-.728-.078-.974-.236-.228-.158-.483-.413-.764-.764L21.19 247.207h.658v12.451c0 .526-.132.929-.395 1.21-.263.281-.65.421-1.158.421Z"
				/>
				<path
					fill="#6FB05C"
					d="M113.489 64.606a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521V64.606Z"
				/>
				<mask id="e" width="53" height="52" x="113" y="57" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M113.489 64.606a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521V64.606Z"
					/>
				</mask>
				<g mask="url(#e)">
					<path
						fill="#649E53"
						d="M165.192 101.268c0 6.23-5.051 11.281-11.281 11.281H124.77c-6.23 0-11.281-5.051-11.281-11.281 0 2.077 3.367 3.761 7.52 3.761h36.663c4.153 0 7.52-1.684 7.52-3.761Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M131.758 92.078c-.421 0-.763-.096-1.027-.29a1.188 1.188 0 0 1-.447-.763c-.053-.333.008-.701.184-1.105l6.949-15.398c.229-.51.5-.869.816-1.08a1.96 1.96 0 0 1 1.132-.342c.403 0 .763.114 1.079.343.334.21.614.57.842 1.079l6.976 15.398c.192.404.263.772.21 1.105a1.201 1.201 0 0 1-.447.79c-.246.176-.571.263-.974.263-.492 0-.877-.114-1.158-.342-.264-.246-.5-.614-.711-1.105l-1.711-3.975 1.421.921h-11.107l1.421-.92-1.685 3.974c-.228.508-.465.877-.71 1.105-.246.228-.597.342-1.053.342Zm7.554-15.108-3.685 8.765-.684-.843h8.791l-.658.843-3.711-8.765h-.053Z"
				/>
				<path
					fill="#6FB05C"
					d="M113.489 121.01a7.52 7.52 0 0 1 7.52-7.521h36.663a7.52 7.52 0 0 1 7.52 7.521v36.662a7.521 7.521 0 0 1-7.52 7.521h-36.663a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
				/>
				<mask id="f" width="53" height="53" x="113" y="113" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M113.489 121.01a7.52 7.52 0 0 1 7.52-7.521h36.663a7.52 7.52 0 0 1 7.52 7.521v36.662a7.521 7.521 0 0 1-7.52 7.521h-36.663a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
					/>
				</mask>
				<g mask="url(#f)">
					<path
						fill="#649E53"
						d="M165.192 157.672c0 6.23-5.051 11.281-11.281 11.281H124.77c-6.23 0-11.281-5.051-11.281-11.281 0 2.077 3.367 3.76 7.52 3.76h36.663c4.153 0 7.52-1.683 7.52-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M135.219 148.272c-.545 0-.966-.15-1.264-.448-.299-.316-.447-.745-.447-1.29v-15.187c0-.562.14-.983.421-1.264.298-.298.719-.447 1.263-.447.544 0 .956.149 1.238.447.298.281.447.702.447 1.264v14.055h7.633c.474 0 .833.124 1.079.369.264.245.395.597.395 1.053 0 .456-.131.816-.395 1.079-.246.245-.605.369-1.079.369h-9.291Z"
				/>
				<path
					fill="#E9BA3A"
					d="M113.489 8.202a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
				/>
				<mask id="g" width="53" height="53" x="113" y="0" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M113.489 8.202a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
					/>
				</mask>
				<g mask="url(#g)">
					<path
						fill="#D1A734"
						d="M165.192 44.865c0 6.23-5.051 11.28-11.281 11.28H124.77c-6.23 0-11.281-5.05-11.281-11.28 0 2.076 3.367 3.76 7.52 3.76h36.663c4.153 0 7.52-1.684 7.52-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M133.559 35.675c-.543 0-.964-.15-1.263-.448-.28-.316-.421-.745-.421-1.29V18.618c0-.561.149-.982.447-1.263.299-.299.72-.448 1.263-.448h6.344c2.036 0 3.606.492 4.712 1.474 1.105.965 1.658 2.325 1.658 4.08 0 1.14-.254 2.123-.764 2.948-.49.825-1.21 1.457-2.158 1.895-.947.44-2.096.658-3.448.658l.237-.394h.868c.721 0 1.343.175 1.869.526.545.351 1.018.904 1.422 1.658l1.974 3.659c.21.368.307.728.289 1.08-.017.35-.148.64-.394.868-.246.21-.606.316-1.08.316-.473 0-.859-.097-1.158-.29-.298-.21-.561-.526-.789-.947l-2.659-4.896c-.316-.597-.693-.992-1.132-1.185-.421-.21-.964-.316-1.632-.316h-2.5v5.896c0 .544-.14.974-.421 1.29-.281.299-.702.448-1.264.448Zm1.685-10.134h4.106c1.211 0 2.123-.246 2.737-.737.632-.509.948-1.264.948-2.264 0-.982-.316-1.72-.948-2.21-.614-.51-1.526-.764-2.737-.764h-4.106v5.975Z"
				/>
				<path
					fill="#E9BA3A"
					d="M226.296 64.606a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521V64.606Z"
				/>
				<mask id="h" width="52" height="52" x="226" y="57" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M226.296 64.606a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521V64.606Z"
					/>
				</mask>
				<g mask="url(#h)">
					<path
						fill="#D1A734"
						d="M277.999 101.268c0 6.23-5.051 11.281-11.28 11.281h-29.142c-6.23 0-11.281-5.051-11.281-11.281 0 2.077 3.367 3.761 7.52 3.761h36.663c4.153 0 7.52-1.684 7.52-3.761Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M247.274 92.078c-.543 0-.965-.149-1.263-.447-.281-.316-.422-.746-.422-1.29v-15.32c0-.56.15-.982.448-1.263.299-.298.72-.447 1.263-.447h6.344c2.036 0 3.606.509 4.711 1.527 1.106 1.017 1.659 2.439 1.659 4.264 0 1.825-.553 3.255-1.659 4.29-1.105 1.018-2.675 1.527-4.711 1.527h-4.685v5.422c0 .544-.141.974-.422 1.29-.28.298-.701.447-1.263.447Zm1.685-9.818h4.158c1.194 0 2.098-.263 2.712-.79.614-.543.921-1.333.921-2.368s-.307-1.816-.921-2.343c-.614-.526-1.518-.79-2.712-.79h-4.158v6.291Z"
				/>
				<path
					fill="#E9BA3A"
					d="M226.296 121.01a7.52 7.52 0 0 1 7.52-7.521h36.663a7.52 7.52 0 0 1 7.52 7.521v36.662a7.521 7.521 0 0 1-7.52 7.521h-36.663a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
				/>
				<mask id="i" width="52" height="53" x="226" y="113" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M226.296 121.01a7.52 7.52 0 0 1 7.52-7.521h36.663a7.52 7.52 0 0 1 7.52 7.521v36.662a7.521 7.521 0 0 1-7.52 7.521h-36.663a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
					/>
				</mask>
				<g mask="url(#i)">
					<path
						fill="#D1A734"
						d="M277.999 157.672c0 6.23-5.051 11.281-11.28 11.281h-29.142c-6.23 0-11.281-5.051-11.281-11.281 0 2.077 3.367 3.76 7.52 3.76h36.663c4.153 0 7.52-1.683 7.52-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M244.329 148.482c-.492 0-.869-.14-1.132-.421-.263-.281-.395-.667-.395-1.158v-15.82c0-.508.14-.895.421-1.158.281-.281.667-.421 1.159-.421.438 0 .78.088 1.026.263.263.158.5.439.711.843l6.501 12.029h-.842l6.501-12.029c.211-.404.438-.685.685-.843.245-.175.587-.263 1.026-.263.491 0 .869.14 1.132.421.263.263.395.65.395 1.158v15.82c0 .491-.132.877-.395 1.158-.246.281-.623.421-1.132.421-.491 0-.868-.14-1.132-.421-.263-.281-.394-.667-.394-1.158v-12.187h.579l-5.528 10.081c-.176.298-.36.518-.553.658-.193.14-.456.211-.789.211-.334 0-.606-.071-.816-.211a2.074 2.074 0 0 1-.553-.658l-5.58-10.108h.632v12.214c0 .491-.132.877-.395 1.158-.247.281-.624.421-1.132.421Z"
				/>
				<path
					fill="#E9BA3A"
					d="M169.893 121.01a7.52 7.52 0 0 1 7.52-7.521h36.662a7.52 7.52 0 0 1 7.521 7.521v36.662a7.521 7.521 0 0 1-7.521 7.521h-36.662a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
				/>
				<mask id="j" width="53" height="53" x="169" y="113" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M169.893 121.01a7.52 7.52 0 0 1 7.52-7.521h36.662a7.52 7.52 0 0 1 7.521 7.521v36.662a7.521 7.521 0 0 1-7.521 7.521h-36.662a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
					/>
				</mask>
				<g mask="url(#j)">
					<path
						fill="#D1A734"
						d="M221.596 157.672c0 6.23-5.051 11.281-11.281 11.281h-29.142c-6.229 0-11.28-5.051-11.28-11.281 0 2.077 3.367 3.76 7.52 3.76h36.662c4.154 0 7.521-1.683 7.521-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M195.285 148.535c-1.79 0-3.36-.395-4.711-1.185-1.334-.789-2.369-1.895-3.106-3.316-.737-1.44-1.106-3.124-1.106-5.054 0-1.456.211-2.764.632-3.922.421-1.176 1.017-2.176 1.79-3.001a7.827 7.827 0 0 1 2.816-1.921c1.106-.456 2.334-.685 3.685-.685 1.807 0 3.378.395 4.712 1.185 1.333.772 2.369 1.869 3.106 3.29.737 1.421 1.105 3.098 1.105 5.028 0 1.456-.21 2.772-.631 3.948-.421 1.176-1.027 2.185-1.816 3.027a7.808 7.808 0 0 1-2.817 1.948c-1.089.439-2.308.658-3.659.658Zm0-2.896c1.141 0 2.106-.263 2.896-.789.806-.527 1.421-1.29 1.842-2.29.438-1.001.658-2.194.658-3.58 0-2.106-.473-3.738-1.421-4.896-.931-1.158-2.255-1.737-3.975-1.737-1.123 0-2.088.263-2.895.789-.808.509-1.431 1.264-1.869 2.264-.421.982-.632 2.176-.632 3.58 0 2.088.474 3.72 1.422 4.896.947 1.176 2.272 1.763 3.974 1.763Z"
				/>
				<path
					fill="#E9BA3A"
					d="M57.085 121.01a7.52 7.52 0 0 1 7.52-7.521h36.663a7.52 7.52 0 0 1 7.521 7.521v36.662a7.521 7.521 0 0 1-7.521 7.521H64.606a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
				/>
				<mask id="k" width="52" height="53" x="57" y="113" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M57.085 121.01a7.52 7.52 0 0 1 7.52-7.521h36.663a7.52 7.52 0 0 1 7.521 7.521v36.662a7.521 7.521 0 0 1-7.521 7.521H64.606a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
					/>
				</mask>
				<g mask="url(#k)">
					<path
						fill="#D1A734"
						d="M108.789 157.672c0 6.23-5.051 11.281-11.28 11.281H68.365c-6.23 0-11.28-5.051-11.28-11.281 0 2.077 3.367 3.76 7.52 3.76h36.662c4.154 0 7.521-1.683 7.521-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M75.355 148.482c-.422 0-.764-.097-1.027-.289a1.186 1.186 0 0 1-.448-.764c-.052-.334.01-.702.185-1.105l6.949-15.399c.228-.508.5-.868.816-1.079.333-.228.71-.342 1.132-.342.403 0 .763.114 1.079.342.333.211.614.571.842 1.079l6.975 15.399c.193.403.263.771.21 1.105a1.2 1.2 0 0 1-.447.79c-.245.176-.57.263-.974.263-.49 0-.877-.114-1.158-.342-.263-.245-.5-.614-.71-1.106l-1.711-3.974 1.421.921H77.381l1.422-.921-1.685 3.974c-.228.509-.465.877-.71 1.106-.246.228-.597.342-1.053.342Zm7.554-15.109-3.685 8.766-.684-.843h8.79l-.657.843-3.712-8.766h-.052Z"
				/>
				<path
					fill="#E9BA3A"
					d="M.682 121.01a7.52 7.52 0 0 1 7.52-7.521h36.662a7.52 7.52 0 0 1 7.521 7.521v36.662a7.521 7.521 0 0 1-7.52 7.521H8.202a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
				/>
				<mask id="l" width="53" height="53" x="0" y="113" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M.682 121.01a7.52 7.52 0 0 1 7.52-7.521h36.662a7.52 7.52 0 0 1 7.521 7.521v36.662a7.521 7.521 0 0 1-7.52 7.521H8.202a7.521 7.521 0 0 1-7.52-7.521V121.01Z"
					/>
				</mask>
				<g mask="url(#l)">
					<path
						fill="#D1A734"
						d="M52.385 157.672c0 6.23-5.05 11.281-11.28 11.281H11.961c-6.23 0-11.28-5.051-11.28-11.281 0 2.077 3.367 3.76 7.52 3.76h36.662c4.154 0 7.521-1.683 7.521-3.76Z"
					/>
				</g>
				<path
					fill="#fff"
					d="M20.752 148.482c-.544 0-.965-.149-1.264-.447-.28-.316-.42-.746-.42-1.29v-15.319c0-.562.149-.983.447-1.264.298-.298.72-.447 1.263-.447h6.344c2.035 0 3.606.491 4.711 1.474 1.106.965 1.659 2.324 1.659 4.08 0 1.14-.255 2.123-.764 2.948-.49.824-1.21 1.456-2.158 1.895-.948.439-2.097.658-3.448.658l.237-.395h.868c.72 0 1.343.176 1.87.526.543.351 1.017.904 1.42 1.659l1.975 3.658c.21.369.307.729.29 1.08-.018.35-.15.64-.396.868-.245.211-.605.316-1.079.316s-.86-.097-1.158-.289c-.298-.211-.561-.527-.79-.948l-2.658-4.896c-.316-.597-.693-.992-1.132-1.184-.421-.211-.965-.316-1.632-.316h-2.5v5.896c0 .544-.14.974-.422 1.29-.28.298-.701.447-1.263.447Zm1.685-10.134h4.106c1.21 0 2.123-.245 2.737-.737.632-.508.948-1.263.948-2.263 0-.983-.316-1.72-.948-2.212-.614-.508-1.526-.763-2.737-.763h-4.106v5.975ZM57.085 8.202a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.521 7.52H64.606a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
				/>
				<mask id="m" width="52" height="53" x="57" y="0" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M57.085 8.202a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.521 7.52H64.606a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
					/>
				</mask>
				<g mask="url(#m)">
					<path
						fill="#D5D7D8"
						d="M108.789 44.865c0 6.23-5.051 11.28-11.28 11.28H68.365c-6.23 0-11.28-5.05-11.28-11.28 0 2.076 3.367 3.76 7.52 3.76h36.662c4.154 0 7.521-1.684 7.521-3.76Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M82.478 35.727c-1.79 0-3.36-.394-4.711-1.184-1.334-.79-2.37-1.895-3.106-3.317-.737-1.439-1.106-3.123-1.106-5.053 0-1.457.21-2.764.632-3.922.421-1.176 1.018-2.176 1.79-3.001a7.82 7.82 0 0 1 2.816-1.922c1.106-.456 2.334-.684 3.685-.684 1.808 0 3.378.395 4.712 1.184 1.334.773 2.369 1.87 3.106 3.29.737 1.422 1.105 3.098 1.105 5.028 0 1.457-.21 2.773-.631 3.948-.422 1.176-1.027 2.185-1.817 3.027a7.802 7.802 0 0 1-2.816 1.948c-1.088.439-2.308.658-3.659.658Zm0-2.895c1.14 0 2.106-.263 2.896-.79.807-.526 1.421-1.29 1.842-2.29.439-1 .658-2.193.658-3.58 0-2.105-.474-3.737-1.421-4.895-.93-1.159-2.255-1.738-3.975-1.738-1.123 0-2.088.264-2.895.79-.807.509-1.43 1.264-1.869 2.264-.421.982-.632 2.176-.632 3.58 0 2.088.474 3.72 1.422 4.895.947 1.176 2.272 1.764 3.974 1.764Z"
				/>
				<path
					fill="#fff"
					d="M.682 64.606a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521H8.202a7.52 7.52 0 0 1-7.52-7.521V64.606Z"
				/>
				<mask id="n" width="53" height="52" x="0" y="57" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M.682 64.606a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521H8.202a7.52 7.52 0 0 1-7.52-7.521V64.606Z"
					/>
				</mask>
				<g mask="url(#n)">
					<path
						fill="#D5D7D8"
						d="M52.385 101.268c0 6.23-5.05 11.281-11.28 11.281H11.961c-6.23 0-11.28-5.051-11.28-11.281 0 2.077 3.367 3.761 7.52 3.761h36.662c4.154 0 7.521-1.684 7.521-3.761Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M26.075 92.078c-.544 0-.965-.149-1.263-.447-.281-.316-.422-.746-.422-1.29V76.127h-5.132c-.456 0-.816-.123-1.08-.368-.245-.263-.368-.614-.368-1.053 0-.456.123-.798.369-1.026.263-.246.623-.369 1.079-.369h13.634c.457 0 .808.123 1.053.368.264.229.395.57.395 1.027 0 .439-.132.79-.395 1.053-.245.245-.596.368-1.053.368H27.76v14.214c0 .544-.14.974-.422 1.29-.28.298-.701.447-1.263.447Z"
				/>
				<path
					fill="#fff"
					d="M169.893 8.202a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.521 7.52h-36.662a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
				/>
				<mask id="o" width="53" height="53" x="169" y="0" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M169.893 8.202a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.521 7.52h-36.662a7.52 7.52 0 0 1-7.52-7.52V8.202Z"
					/>
				</mask>
				<g mask="url(#o)">
					<path
						fill="#D5D7D8"
						d="M221.596 44.865c0 6.23-5.051 11.28-11.281 11.28h-29.142c-6.229 0-11.28-5.05-11.28-11.28 0 2.076 3.367 3.76 7.52 3.76h36.662c4.154 0 7.521-1.684 7.521-3.76Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M195.285 35.727c-1.79 0-3.36-.394-4.711-1.184-1.334-.79-2.369-1.895-3.106-3.317-.737-1.439-1.106-3.123-1.106-5.053 0-1.457.211-2.764.632-3.922.421-1.176 1.017-2.176 1.79-3.001a7.821 7.821 0 0 1 2.816-1.922c1.106-.456 2.334-.684 3.685-.684 1.807 0 3.378.395 4.712 1.184 1.333.773 2.369 1.87 3.106 3.29.737 1.422 1.105 3.098 1.105 5.028 0 1.457-.21 2.773-.631 3.948-.421 1.176-1.027 2.185-1.816 3.027a7.808 7.808 0 0 1-2.817 1.948c-1.089.439-2.308.658-3.659.658Zm0-2.895c1.141 0 2.106-.263 2.896-.79.806-.526 1.421-1.29 1.842-2.29.438-1 .658-2.193.658-3.58 0-2.105-.473-3.737-1.421-4.895-.931-1.159-2.255-1.738-3.975-1.738-1.123 0-2.088.264-2.895.79-.808.509-1.431 1.264-1.869 2.264-.421.982-.632 2.176-.632 3.58 0 2.088.474 3.72 1.422 4.895.947 1.176 2.272 1.764 3.974 1.764Z"
				/>
				<path
					fill="#fff"
					d="M226.296 177.413a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52v-36.663Z"
				/>
				<mask id="p" width="52" height="53" x="226" y="169" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M226.296 177.413a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52v-36.663Z"
					/>
				</mask>
				<g mask="url(#p)">
					<path
						fill="#D5D7D8"
						d="M277.999 214.076c0 6.23-5.051 11.281-11.28 11.281h-29.142c-6.23 0-11.281-5.051-11.281-11.281 0 2.076 3.367 3.76 7.52 3.76h36.663c4.153 0 7.52-1.684 7.52-3.76Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M248.112 204.675c-.579 0-1.027-.149-1.343-.447-.298-.316-.447-.764-.447-1.343v-14.977c0-.579.149-1.018.447-1.316.316-.316.764-.474 1.343-.474h9.397c.439 0 .772.114 1 .343.246.228.368.552.368.973 0 .439-.122.782-.368 1.027-.228.228-.561.342-1 .342h-7.95v5.107h7.37c.457 0 .799.113 1.027.342.228.228.342.561.342 1 0 .439-.114.772-.342 1-.228.229-.57.342-1.027.342h-7.37v5.396h7.95c.439 0 .772.114 1 .343.246.228.368.561.368 1 0 .439-.122.772-.368 1-.228.228-.561.342-1 .342h-9.397Z"
				/>
				<path
					fill="#fff"
					d="M113.489 177.413a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52v-36.663Z"
				/>
				<mask id="q" width="53" height="53" x="113" y="169" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M113.489 177.413a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52h-36.663a7.52 7.52 0 0 1-7.52-7.52v-36.663Z"
					/>
				</mask>
				<g mask="url(#q)">
					<path
						fill="#D5D7D8"
						d="M165.192 214.076c0 6.23-5.051 11.281-11.281 11.281H124.77c-6.23 0-11.281-5.051-11.281-11.281 0 2.076 3.367 3.76 7.52 3.76h36.663c4.153 0 7.52-1.684 7.52-3.76Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M138.868 204.886c-.545 0-.966-.15-1.264-.448-.281-.316-.421-.745-.421-1.289v-15.504c0-.561.14-.992.421-1.29.298-.298.719-.447 1.264-.447.543 0 .956.149 1.237.447.298.298.447.729.447 1.29v15.504c0 .544-.141.973-.421 1.289-.281.298-.702.448-1.263.448Z"
				/>
				<path
					fill="#fff"
					d="M.682 177.413a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52H8.202a7.52 7.52 0 0 1-7.52-7.52v-36.663Z"
				/>
				<mask id="r" width="53" height="53" x="0" y="169" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M.682 177.413a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.663a7.52 7.52 0 0 1-7.52 7.52H8.202a7.52 7.52 0 0 1-7.52-7.52v-36.663Z"
					/>
				</mask>
				<g mask="url(#r)">
					<path
						fill="#D5D7D8"
						d="M52.385 214.076c0 6.23-5.05 11.281-11.28 11.281H11.961c-6.23 0-11.28-5.051-11.28-11.281 0 2.076 3.367 3.76 7.52 3.76h36.662c4.154 0 7.521-1.684 7.521-3.76Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M26.556 204.938c-1.28 0-2.413-.166-3.395-.5-.965-.333-1.781-.824-2.448-1.474-.667-.666-1.167-1.492-1.5-2.474-.334-.982-.5-2.124-.5-3.422v-9.449c0-.579.14-1.009.42-1.29.299-.281.72-.421 1.264-.421s.956.14 1.237.421c.298.281.448.711.448 1.29v9.423c0 1.667.377 2.922 1.131 3.764.772.824 1.887 1.237 3.343 1.237 1.457 0 2.562-.413 3.317-1.237.754-.842 1.132-2.097 1.132-3.764v-9.423c0-.579.14-1.009.42-1.29.3-.281.72-.421 1.264-.421.527 0 .93.14 1.211.421.298.281.447.711.447 1.29v9.449c0 1.72-.298 3.167-.894 4.343a5.796 5.796 0 0 1-2.606 2.659c-1.158.579-2.589.868-4.29.868Z"
				/>
				<path
					fill="#fff"
					d="M113.489 233.817a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
				/>
				<mask id="s" width="53" height="52" x="113" y="226" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M113.489 233.817a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.52 7.52v36.662a7.52 7.52 0 0 1-7.52 7.521h-36.663a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
					/>
				</mask>
				<g mask="url(#s)">
					<path
						fill="#D5D7D8"
						d="M165.192 270.479c0 6.23-5.051 11.281-11.281 11.281H124.77c-6.23 0-11.281-5.051-11.281-11.281 0 2.077 3.367 3.761 7.52 3.761h36.663c4.153 0 7.52-1.684 7.52-3.761Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M135.219 261.079c-.545 0-.966-.15-1.264-.448-.299-.315-.447-.745-.447-1.289v-15.188c0-.561.14-.982.421-1.263.298-.298.719-.448 1.263-.448.544 0 .956.15 1.238.448.298.281.447.702.447 1.263v14.056h7.633c.474 0 .833.123 1.079.368.264.246.395.597.395 1.053 0 .456-.131.816-.395 1.079-.246.246-.605.369-1.079.369h-9.291Z"
				/>
				<path
					fill="#fff"
					d="M57.085 233.817a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.521 7.521H64.606a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
				/>
				<mask id="t" width="52" height="52" x="57" y="226" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M57.085 233.817a7.52 7.52 0 0 1 7.52-7.52h36.663a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.521 7.521H64.606a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
					/>
				</mask>
				<g mask="url(#t)">
					<path
						fill="#D5D7D8"
						d="M108.789 270.479c0 6.23-5.051 11.281-11.28 11.281H68.365c-6.23 0-11.28-5.051-11.28-11.281 0 2.077 3.367 3.761 7.52 3.761h36.662c4.154 0 7.521-1.684 7.521-3.761Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M77.223 261.079c-.58 0-1.027-.15-1.342-.448-.299-.315-.448-.763-.448-1.342v-14.977c0-.579.15-1.018.448-1.316.315-.316.763-.474 1.342-.474h6.317c1.299 0 2.404.193 3.317.579.912.387 1.605.939 2.08 1.658.49.72.736 1.58.736 2.58 0 1.123-.324 2.071-.974 2.843-.649.771-1.535 1.289-2.658 1.553v-.395c1.298.193 2.307.684 3.027 1.474.72.79 1.079 1.816 1.079 3.08 0 1.649-.562 2.93-1.685 3.842-1.105.895-2.65 1.343-4.632 1.343h-6.607Zm1.448-2.553h4.738c1.193 0 2.07-.229 2.632-.685.561-.473.842-1.176.842-2.105 0-.948-.28-1.65-.842-2.106-.562-.456-1.44-.685-2.632-.685H78.67v5.581Zm0-8.134h4.29c1.158 0 2.018-.219 2.58-.658.579-.456.868-1.123.868-2s-.29-1.535-.868-1.974c-.562-.456-1.422-.685-2.58-.685h-4.29v5.317Z"
				/>
				<path
					fill="#fff"
					d="M169.893 233.817a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.521 7.521h-36.662a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
				/>
				<mask id="u" width="53" height="52" x="169" y="226" maskUnits="userSpaceOnUse">
					<path
						fill="#fff"
						d="M169.893 233.817a7.52 7.52 0 0 1 7.52-7.52h36.662a7.52 7.52 0 0 1 7.521 7.52v36.662a7.52 7.52 0 0 1-7.521 7.521h-36.662a7.52 7.52 0 0 1-7.52-7.521v-36.662Z"
					/>
				</mask>
				<g mask="url(#u)">
					<path
						fill="#D5D7D8"
						d="M221.596 270.479c0 6.23-5.051 11.281-11.281 11.281h-29.142c-6.229 0-11.28-5.051-11.28-11.281 0 2.077 3.367 3.761 7.52 3.761h36.662c4.154 0 7.521-1.684 7.521-3.761Z"
					/>
				</g>
				<path
					fill="#1A1A1B"
					d="M189.066 261.079c-.579 0-1.027-.15-1.343-.448-.298-.315-.447-.763-.447-1.342v-14.977c0-.579.149-1.018.447-1.316.316-.316.764-.474 1.343-.474h5.106c3.106 0 5.501.798 7.186 2.395 1.702 1.598 2.553 3.887 2.553 6.87 0 1.492-.219 2.817-.658 3.975-.421 1.14-1.053 2.106-1.895 2.895-.824.79-1.843 1.395-3.053 1.816-1.193.404-2.571.606-4.133.606h-5.106Zm1.579-2.79h3.317c1.087 0 2.026-.132 2.816-.395.808-.281 1.474-.684 2-1.211.545-.544.948-1.219 1.211-2.027.263-.824.395-1.781.395-2.869 0-2.176-.535-3.798-1.606-4.869-1.07-1.071-2.676-1.606-4.816-1.606h-3.317v12.977Z"
				/>
			</svg>
		</a>
	</div>
)

export default Waffle
